<template>
  <div class="app-steps">
    <div class="app-steps-indicator row">
      <!-- Status Column -->
      <div
        v-for="(status, index) in statusList"
        :key="`app-steps-status-${index}`"
        class="mb-5 d-flex align-items-center flex-column"
        :class="[
          `col-${colSize}`,
          index < activeStep ? 'text-info' : 'text-gray',
        ]"
      >
        <!-- Status Icon and Line -->
        <div class="d-flex justify-content-end">
          <div
            v-if="index > 0 && (index + 1) % colNum !== 1"
            class="app-steps-indicator-line"
            :class="index < activeStep ? 'bg-info' : 'bg-gray-light-3'"
          ></div>
          <div
            class="pl-2 pr-2 bg-white"
            style="position: relative; z-index: 10"
          >
            <div
              class="
                app-steps-indicator-icon
                rounded-circle
                d-flex
                align-items-center
                justify-content-center
                p-3
              "
              :class="
                index < activeStep ? 'bg-info text-white' : 'bg-gray-light-3'
              "
            >
              <span v-if="index + 2 > activeStep">{{ index + 1 }}</span>
              <AppIcon v-else name="checkmark"></AppIcon>
            </div>
          </div>
        </div>
        <span class="mt-2 mt-lg-0 text-center text-body-3 text-lg-body-2">
          {{ status }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import AppIcon from '@/shared/elements/AppIcon.vue';
export default {
  name: 'AppSteps',

  props: {
    activeStep: { type: Number, default: 1 },
    statusList: { type: Array, default: () => [] },
  },

  computed: {
    colSize() {
      return Math.ceil(12 / this.statusList.length);
    },
    colNum() {
      return Math.ceil(12 / Math.ceil(12 / this.statusList.length));
    },
  },

  components: { AppIcon },
};
</script>

<style lang="scss" scoped>
.app-steps {
  &-indicator {
    &-icon {
      position: relative;
      z-index: 10;
      height: 20px;
      width: 20px;
    }
    &-line {
      // margin-right: 5%;
      margin-left: 5%;
      margin-top: 0.7rem;
      height: 3px;
      width: 100%;
      position: absolute;
    }
  }
}
</style>
